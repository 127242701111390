
export default {
  name: 'BaseLink',

  props: {
    to: {
      type: [String, Object],
      default: undefined,
    },

    translate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component () {
      if (!this.to) {
        return 'div'
      }

      return this.to?.startsWith?.(this.$config.appUrl) || this.to?.startsWith?.('/')
        ? 'NuxtLink'
        : 'a'
    },

    opts () {
      if (!this.to) {
        return {}
      }

      const url = this.to.replace(this.$config.appUrl, '')

      return this.to?.startsWith?.(this.$config.appUrl) || this.to?.startsWith?.('/')
        ? {
            to: this.translate ? this.localePath(url) : url,
          }
        : {
            href: this.to,
            target: '_blank',
          }
    },
  },
}
